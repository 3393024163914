import React, { Component } from 'react';
import { Doughnut, defaults } from 'react-chartjs-2';
import PropTypes from 'prop-types';

export default class DoughnutChart extends Component {
  static propTypes = {
    labels: PropTypes.array,
    values: PropTypes.array,
    data: PropTypes.object,
    name: PropTypes.string
  };

  componentWillMount() {
    defaults.global.defaultFontColor = '#58585880';
  }

  render() {
    const { data: { labels, values } } = this.props;

    if (!labels || !values) {
      return <div />;
    }

    const legendOpts = {
      position: 'bottom',
      onClick: null
    };

    const data = {
      labels: [...labels],
      datasets: [{
        data: [...values],
        backgroundColor: ['#5450AF', '#8B51AE', '#BBB9E0', '#D1B9DF', '#732BF5'],
      }]
    };
    const options = {
      tooltips: {
        backgroundColor: '#104b7f',
        titleFontStyle: 'normal',
        bodyFontStyle: 'bold',
        yPadding: 10,
        xPadding: 10,
        cornerRadius: 0,
        displayColors: false,
        borderColor: '#fff',
        borderWidth: '1',
        callbacks: {
          label(tooltipItem, data) {
            const label = data.labels[tooltipItem.index];
            const percent = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return `${label}  ${Math.round(percent * 100) / 100}%`;
          }
        }
      }
    };

    return (
      <div>
        <Doughnut
          data={data}
          legend={legendOpts}
          options={options}
          width={640}
          height={640}
        />
      </div>
    );
  }
}
